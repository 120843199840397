import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, AlertController } from '@ionic/angular';
import { AppModule } from 'src/app/app.module';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, interval } from 'rxjs';
import { addIcons } from 'ionicons';
import {
    play,
    stop,
    caretDownSharp,
    caretUpSharp,
    radioButtonOnOutline,
    informationCircleOutline,
} from 'ionicons/icons';
import { StaffTimetrackerGatewayService } from 'src/app/staff/services/staff-timetracker-gateway.service';
import moment from 'moment';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonService } from '../../services/common.service';
import { StaffTimelogsGatewayService } from 'src/app/staff/services/staff-timelogs-gateway.service';
import { StatusLogsService } from 'src/app/common/services/status-logs.service';
import { StaffAvatarComponent } from 'src/app/staff/components/staff-avatar/staff-avatar.component';

@Component({
    selector: 'app-staff-profile',
    templateUrl: './staff-profile.component.html',
    standalone: true,
    imports: [IonicModule, AppModule, FormsModule, StaffAvatarComponent],
})
export class StaffProfileComponent {
    private timerSubscription: any;

    private user$: Observable<any>;

    public user: any;
    public statusLogs: any;
    public status: any;
    public selectedStatus = '';
    public avatar: string = '../../../assets/avatar.svg';

    public processing = false;
    public timer: any = 0;

    constructor(
        private AlertController: AlertController,
        private StorageMap: StorageMap,
        private CommonService: CommonService,
        private AuthService: AuthService,
        private StaffTimetrackerGatewayService: StaffTimetrackerGatewayService,
        private StaffTimelogsGatewayService: StaffTimelogsGatewayService,
        private StatusLogsService: StatusLogsService,
    ) {
        this.user$ = this.StorageMap.watch('user');
        this.user$.subscribe((user) => {
            this.user = user;

            if (this.user?.timelog_start) {
                this.startTimer();
            }

            if (this.user) {
                this.selectedStatus = this.user.status;

                if (this.user.avatar) {
                    this.avatar = this.user.avatar;
                }
            }
        });

        addIcons({
            play,
            stop,
            caretDownSharp,
            caretUpSharp,
            radioButtonOnOutline,
            informationCircleOutline,
        });
    }

    public onStartTimer = () => {
        this.processing = true;

        this.StaffTimetrackerGatewayService.startStaffTimetracker().subscribe(
            (res: any) => {
                if (!res.success) {
                    this.AuthService.relogin(res, 'Error starting timetracker');
                    return;
                }

                this.AuthService.setUserTimelogData(res.staff);
                this.startTimer();
                this.CommonService.createToast(
                    'Timetracker started successfully'
                );
            },
            (err: any) => {
                this.CommonService.createToast('Error starting timetracker');
            },
            () => {
                this.processing = false;
            }
        );
    };

    public startTimer = () => {
        this.timerSubscription = interval(1000).subscribe(() => {
            try {
                const startTime = moment(this.user.timelog_start);
                const currentTime = moment();
                this.timer = currentTime.diff(startTime);
            } catch (err: any) {
                this.timerSubscription.unsubscribe();
            }
        });
    };

    public promptStopTime = async () => {
        const alert = await this.AlertController.create({
            header: 'Timelog',
            subHeader: '',
            message: `Are you sure you want to end your shift?`,
            inputs: [
                {
                    name: 'staff_notes',
                    type: 'text',
                    placeholder: 'Notes',
                    label: 'Notes',
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'End Shift',
                    role: 'confirm',
                    handler: (data: any) => {
                        this.processing = true;

                        this.StaffTimetrackerGatewayService.stopStaffTimetracker(data).subscribe(
                            (res: any) => {
                                if (!res.success) {
                                    this.AuthService.relogin(res, res.error);
                                    return;
                                }

                                if (res.alert) {
                                    this.showAlertError(res);
                                    return;
                                }

                                this.AuthService.setUserTimelogData(res.staff);
                                this.CommonService.createToast('Timetracker stopped successfully');
                            },
                            (err: any) => {
                                this.CommonService.createToast('Error stopping timetracker');
                            },
                            () => {
                                this.processing = false;
                            }
                        );
                    },
                },
            ],
        });

        await alert.present();
    };

    public onStaffStatusChange(event: any) {
        this.processing = true;

        const selectedStaffStatus = {
            status: event.detail.value,
        };

        this.StaffTimetrackerGatewayService.updateStaffStatus(
            selectedStaffStatus
        ).subscribe(
            (res: any) => {
                if (!res.success) {
                    this.AuthService.relogin(res, 'Error changing status');
                    this.selectedStatus = this.user.status;
                    return;
                }

                this.AuthService.setUserTimelogData(res.staff);
                this.selectedStatus = this.user.status;
                this.CommonService.createToast('Status changed successfully');
            },
            (err: any) => {
                this.CommonService.createToast('Error changing status');
            },
            () => {
                this.processing = false;
            }
        );
    }

    public openStatusLogsModal = async () => {
        const date = moment(this.user.timelog_start).format('YYYY-MM-DD');

        await this.StaffTimelogsGatewayService.getStaffTimelog(
            this.user.email,
            date
        ).subscribe(
            (res: any) => {
                if (!res.success) {
                    this.AuthService.relogin(res, 'Error fetching status logs');
                    return;
                }
                
                const statusLogs = res.staff_timelogs[0].status_logs;

                this.StatusLogsService.openStatusLogsModal(
                    statusLogs,
                    this.user.name,
                    date
                );
            },
            (err: any) => {
                this.CommonService.createToast('Error fetching status logs');
            }
        );
    };

    public showAlertError = async (response: any) => {
        const alert = await this.AlertController.create({
            header: response.error,
            cssClass: 'alert-error-box',
            message: response.alert,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Ok, Reset Timetracker',
                    role: 'confirm',
                    handler: () => {
                        this.AuthService.setUserTimelogData(response.staff);
                        this.CommonService.createToast('Timetracker Cleared');
                    }
                },
            ],
        });
    
        await alert.present();
    }
}
